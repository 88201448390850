<!-- <div class="nav-bar">
  <img src="../assets/logos/gva-logo.png" class="logo">
</div> -->
<div class="main">
  <router-outlet></router-outlet>
</div>
<div class="phone1">
  <!-- <h2>Please switch to desktop view</h2> -->
</div>
<div class="container-fluid" *ngIf="url != '/' && url != '/about' && url != '/about?id=contact' && url != '/QCResults' && !url.includes('qcresultid') && url != '/privacypolicy' && url != '/terms-of-use'">
     <div class="row">
          <div class="col-md-12 footer mb-0">
               &copy; {{currentYear}} Semantic Web Tech Pvt Ltd.
          </div>
          <div class="col-md-12 footer mt-10">
               {{version}}
          </div>
     </div>
</div>
