export interface Country {
     name: string;
     code: string;
}
export interface CountryAPI {
     [key: number]: string; 
   }
export interface LoginDialogData {
     action: string;
     data: {
          username: string;
          password: string;
     }
}

export interface RequestTrial {
     first_name: string;
     last_name: string;
     title: string;
     short_name: string;
     qualification: string;
     organization: string;
     email: string;
     country: string;
     mobile_number: string;
     password1?: string;
     password2?: string;
     username?: string;
     server?: string;
     request_type?: string; 
     baseURL?: string;
}

export interface RequestTrialPayLoad {
     status: string;
     data?: string;
}

export interface ResetPasswordPayLoad {
     status: string;
     data?: string;
}

export interface RequestTrailConfirmAPI {
     profile: {
          country: string;
          created_at: string;
          mobile_number: string;
          org_id: number;
          organization: string;
          qualification: string;
          short_name: string;
          title: string;
     },
     user: {
          email: string;
          first_name: string;
          last_name: string;
          username: string;
     }
}

export const TITLES:string[] = ["Dr.", "Prof.", "Mr.", "Ms.", "Others"];

export const TOPICS:string[] = ["Platform", "Services"];

export class User {
     constructor(
          public username: string,
          private _token: string,
          private tokenExpirationDate: Date,
          private short_name: string,
          private orgId: string,
          private org_name: string,
          private user_id: string,
          private rememberMe?: boolean
     ) {}
     get token() {
          if (!this.tokenExpirationDate || this.tokenExpirationDate <= new Date()) {
               return null;
          } else {
               return this._token;
          }
     }
}
