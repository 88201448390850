import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {AuthGuard} from './auth.guard';

import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: 'home',
    redirectTo: '',
  },
  {
    path: 'createnewcase',
    loadChildren: () =>
      import('./create-new-case/create-new-case.module').then(
        (m) => m.CreateNewCaseModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(
      (m) => m.SettingsModule
    ),
    canLoad: [AuthGuard],
  },
  {
    path: 'data',
    loadChildren: () => import('./data/data.module').then((m) => m.Data),
    canLoad: [AuthGuard],
  },
  {
     path: 'loadfiles',
     loadChildren: () => import('./load-files/load-files.module').then(
       (m) => m.LoadFilesModule
     ),
     canLoad: [AuthGuard],
   },
   {
     path: 'knowledgebase',
     loadChildren: () => import('./knowledge-base/knowledge-base.module').then(
       (m) => m.KnowledgeBaseModule
     ),
     canLoad: [AuthGuard],
   },
  {
    path: 'reset/:email',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomeModule),
  },
  {
     path: 'activate/:email',
     loadChildren: () =>
     import('./home/home.module').then((m) => m.HomeModule),
   },
  // {
  //   path: 'manageworkflow',
  //   loadChildren: () =>
  //     import('./manage-workflow/manage-workflow.module').then(
  //       (m) => m.ManageWorkflowModule
  //     ),
  //   canLoad: [AuthGuard],
  // },
  {
    path: 'reportgeneration',
    loadChildren: () => import('./report-generation/report-generation.module').then(
      (m) => m.ReportGenerationModule
    ),
    // canLoad: [AuthGuard],
  },

  {
    path: 'cases',
    loadChildren: () => import('./cases/cases.module').then(
      (m) => m.CasesModule
    ),
    // canLoad: [AuthGuard],
  },

  {
    path: 'updateprofile',
    loadChildren: () => import('./profile/update-profile.module').then((m)=> m.UpdateProfileModule),
    canLoad: [AuthGuard],
  },

  {
     path: 'users',
     loadChildren: () => import('./users/users.module').then((m)=> m.UsersModule),
     canLoad: [AuthGuard],
   },


   {
     path: 'subscriptions',
     loadChildren: () => import('./subscription/subscription.module').then((m)=> m.SubscriptionModule),
     canLoad: [AuthGuard],
   },

  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
     path: 'about',
     loadChildren: () => import('./about/about.module').then((m) => m.AboutModule),
   },
   {
     path: 'QCResults',
     loadChildren: () => import('./qc-results/qc-results.module').then((m) => m.QCResultsModule),
   },
  {
          path: 'privacypolicy',
          loadChildren: () => import('./privacy-policy/privacy-policy.module').then((m) => m. PrivacyPolicyModule),
     },
     {
          path: 'terms-of-use',
          loadChildren: () => import('./terms-of-use/terms-of-use.module').then((m) => m. TermsOfUseModule),
     },
  {
    path: '**',
    redirectTo: '',
  },


  // for settings tab
  // { path: 'gene-variant-analysis', component: GeneVariantAnalysisComponent },
  // {
  //   path: 'gene-variant-analysis',
  //   loadChildren: () =>
  //     import('./gene-variant-analysis/gene-variant-analysis.module').then(
  //       (m) => m.GeneVariantAnalysis
  //     ),
  //   canLoad: [AuthGuard],
  // },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
